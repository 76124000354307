import dayjs from "dayjs";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_PROJECT, TAG_TYPE_PROJECT_SHIFT } from "./tags";
import { Shift } from "../types/shift";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

//@ts-ignore
const myTimezone = dayjs.tz.guess();

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_PROJECT_SHIFT, TAG_TYPE_PROJECT_SHIFT],
});

export const projectShiftApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjectShifts: builder.query<
      QueryResponse<Shift>,
      {
        page?: number;
        pageSize?: number;
        project_id: number;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ page = 0, pageSize = 100, project_id }) => ({
        url: `projects/${project_id}/shift`,
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data)
          return [{ type: TAG_TYPE_PROJECT_SHIFT, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_PROJECT_SHIFT, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_PROJECT_SHIFT, id })),
        ];
      },
    }),
    addProjectShift: builder.mutation<
      Shift,
      Omit<Shift, "id"> & { project_id: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `projects/${body.project_id}/shift`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT_SHIFT, id: "PARTIAL-LIST" },
        { type: TAG_TYPE_PROJECT, id: data.project_id },
      ],
    }),
    editProjectShift: builder.mutation<
      Shift,
      Partial<Shift> & Pick<Shift, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `projects/${body.project_id}/${body.id}/shift`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT_SHIFT, id: data.id },
        { type: TAG_TYPE_PROJECT_SHIFT, id: "PARTIAL-LIST" },
        { type: TAG_TYPE_PROJECT, id: data.project_id },
      ],
    }),
    deleteProjectShift: builder.mutation<
      void,
      { id: number; project_id: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ id, project_id }) => ({
        url: `projects/${project_id}/${id}/shift`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT_SHIFT, id: data.id },
        { type: TAG_TYPE_PROJECT_SHIFT, id: "PARTIAL-LIST" },
        { type: TAG_TYPE_PROJECT, id: data.project_id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectShiftsQuery,
  useAddProjectShiftMutation,
  useEditProjectShiftMutation,
  useDeleteProjectShiftMutation,
} = projectShiftApi;
