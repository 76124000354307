import React from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  LinearProgress,
  Link,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useDebounce from "../../hooks/useDebounce";
import { useGetTalentsQuery } from "../../services/talent.service";
import { useGetFavoriteTalentsQuery } from "../../services/favoriteTalent.service";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  GridToolbarContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Talent, FavoriteTalent } from "../../types/users";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { Project } from "../../types/project";
import ModalTalentDetail from "./ModalTalentDetail";
import { useInviteTalentMutation } from "../../services/projectJob.service";
import { toast } from "react-toastify";
import { useGetProjectBidsQuery } from "../../services/projectJob.service";
import { useCompany } from "../../hooks/useCompany";
import { filterPrivateTalent, getPrivateCompanyName } from "../../utils/helper";

interface ModalInviteProps {
  open: boolean;
  onClose: () => void;
  project: Project;
  showFav: boolean;
  setShowFav: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalInvite = ({
  open,
  onClose,
  project,
  showFav,
  setShowFav,
}: ModalInviteProps) => {
  const company = useCompany();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  const [talentModal, setTalentModal] = React.useState<{
    visible: boolean;
    talent: Talent | null;
  }>({
    visible: false,
    talent: null,
  });

  const { data: bids, refetch } = useGetProjectBidsQuery(project.id, {
    skip: !open,
  });

  const {
    data: dataTalent,
    isLoading: isLoadingTalent,
    isFetching: isFetchingTalent,
  } = useGetTalentsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const {
    data: dataFavTalent,
    isLoading: isLoadingFavTalent,
    isFetching: isFetchingFavTalent,
  } = useGetFavoriteTalentsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const [doInvite, { isLoading: isInviting }] = useInviteTalentMutation();

  const rowTalents: Talent[] | undefined = React.useMemo(
    () => filterPrivateTalent(dataTalent?.data, company),
    [dataTalent]
  );
  const rowFavTalents: FavoriteTalent[] | undefined = React.useMemo(
    () => dataFavTalent?.data,
    [dataFavTalent]
  );

  const handleInvite = React.useCallback(
    async (resource_id: number) => {
      try {
        await doInvite({ project_id: project.id, resource_id }).unwrap();
      } catch (err: any) {
        toast.error(err);
      }
    },
    [project]
  );

  const columnTalents: GridColDef[] = React.useMemo(
    () => [
      {
        field: "first_name",
        headerName: "Talent",
        flex: 1,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
          <Stack direction={"row"}>
            <Avatar
              alt={`${params.row.first_name}`}
              src={`${params.row?.photo?.[0]?.original_url}`}
            />
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                setTalentModal({ visible: true, talent: params.row })
              }
              sx={{ ml: 1 }}
            >
              {params.row.full_name}
            </Link>
          </Stack>
        ),
      },
      {
        field: "rating",
        headerName: "Rating",
        minWidth: 150,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
          <Rating value={params.row.total_rating} readOnly precision={0.5} />
        ),
      },
      {
        field: "min_pay",
        headerName: "Rate ($)",
        minWidth: 150,
        sortable: true,
        filterable: true,
        valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
          `${params.row.min_pay || "?"} - ${params.row.max_pay || "?"}`,
      },
      {
        field: "city",
        headerName: "City",
        minWidth: 150,
        sortable: true,
        filterable: true,
      },
      {
        field: "state",
        headerName: "State",
        minWidth: 150,
        sortable: true,
        filterable: true,
      },
      {
        field: "actions",
        headerName: "",
        renderCell: (params: GridValueGetterParams<undefined, Talent>) => {
          const alreadyBid = bids?.find(
            (it) => it.resource_id == params.row.id
          );
          if (alreadyBid) return <></>;
          return (
            <Button
              variant="contained"
              color="info"
              onClick={() => handleInvite(params.row.id)}
            >
              Invite
            </Button>
          );
        },
      },
    ],
    [bids]
  );

  const columnFavTalents: GridColDef[] = React.useMemo(
    () => [
      {
        field: "first_name",
        headerName: "Talent",
        flex: 1,
        sortable: false,
        filterable: true,
        renderCell: (
          params: GridRenderCellParams<undefined, FavoriteTalent>
        ) => (
          <Stack direction={"row"}>
            <Avatar
              alt={`${params.row.user.first_name}`}
              src={`${params.row?.user.photo?.[0]?.original_url}`}
            />
            <Link
              component="button"
              variant="body2"
              onClick={() =>
                setTalentModal({ visible: true, talent: params.row.user })
              }
              sx={{ ml: 1 }}
            >
              {params.row.user.full_name}
            </Link>
          </Stack>
        ),
      },
      {
        field: "rating",
        headerName: "Rating",
        minWidth: 150,
        sortable: false,
        filterable: true,
        renderCell: (
          params: GridRenderCellParams<undefined, FavoriteTalent>
        ) => (
          <Rating
            value={params.row.user.total_rating}
            readOnly
            precision={0.5}
          />
        ),
      },
      {
        field: "min_pay",
        headerName: "Rate ($)",
        minWidth: 150,
        sortable: false,
        filterable: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) =>
          `${params.row.user.min_pay || "?"} - ${
            params.row.user.max_pay || "?"
          }`,
      },
      {
        field: "city",
        headerName: "City",
        minWidth: 150,
        sortable: false,
        filterable: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.city}`,
      },
      {
        field: "state",
        headerName: "State",
        minWidth: 150,
        sortable: false,
        filterable: true,
        valueGetter: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => `${params.row.user.state}`,
      },
      {
        field: "actions",
        headerName: "",
        renderCell: (
          params: GridValueGetterParams<undefined, FavoriteTalent>
        ) => {
          const alreadyBid = bids?.find(
            (it) => it.resource_id == params.row.user.id
          );
          if (alreadyBid) return <></>;
          return (
            <Button
              variant="outlined"
              color="info"
              onClick={() => handleInvite(params.row.user.id)}
            >
              Invite
            </Button>
          );
        },
      },
    ],
    [bids]
  );

  React.useEffect(() => {
    setSearchQuery(debounceQuery);
  }, [debounceQuery]);

  const TableToolbar = ({
    onChangeFav,
  }: {
    onChangeFav: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }) => (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack
        spacing={1}
        style={{ flex: 1 }}
        direction="row"
        justifyContent="flex-end"
      >
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={onChangeFav} />}
            label="Only Favorite Talents"
          />
        </FormGroup>
      </Stack>
    </GridToolbarContainer>
  );

  const handleChangeFav = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowFav(event.target.checked);
    },
    []
  );

  const CustomToolbar = React.useMemo(
    () => () => <TableToolbar onChangeFav={handleChangeFav} />,
    [setQuery, handleChangeFav]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xl">
      <DialogTitle>Invite Talent</DialogTitle>
      <DialogContent>
        <Paper style={{ height: "70vh" }}>
          {showFav ? (
            <>
              <DataGrid
                rows={rowFavTalents || []}
                columns={columnFavTalents}
                paginationMode="server"
                // rowCount={rowCount}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                pageSize={pageSize}
                rowsPerPageOptions={[1000]}
                onPageSizeChange={(newSize) => setPageSize(newSize)}
                sortModel={sortModel}
                sortingMode="server"
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                loading={
                  isLoadingFavTalent || isFetchingFavTalent || isInviting
                }
                components={{
                  LoadingOverlay: LinearProgress,
                  NoResultsOverlay: NoResultsOverlay,
                  Toolbar: CustomToolbar,
                }}
                disableSelectionOnClick
                disableColumnFilter
                rowHeight={72}
                density="compact"
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f5f5f5",
                    borderTop: "1px solid #ddd",
                  },
                }}
              />
            </>
          ) : (
            <>
              <DataGrid
                rows={rowTalents || []}
                columns={columnTalents}
                paginationMode="server"
                // rowCount={rowCount}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                pageSize={pageSize}
                rowsPerPageOptions={[1000]}
                onPageSizeChange={(newSize) => setPageSize(newSize)}
                sortModel={sortModel}
                sortingMode="server"
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                loading={isLoadingTalent || isFetchingTalent || isInviting}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoResultsOverlay: NoResultsOverlay,
                  Toolbar: CustomToolbar,
                }}
                disableSelectionOnClick
                disableColumnFilter
                rowHeight={72}
                density="compact"
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f5f5f5",
                    borderTop: "1px solid #ddd",
                  },
                }}
              />
            </>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Done
        </Button>
      </DialogActions>
      <ModalTalentDetail
        open={talentModal.visible}
        onClose={() => setTalentModal({ visible: false, talent: null })}
        talent={talentModal.talent}
      />
    </Dialog>
  );
};
export default ModalInvite;
