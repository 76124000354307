import { Customer } from "./customer";
import { Company } from "./company";
import { Location } from "./location";
import { Manager, Talent, User } from "./users";
import { Skill } from "./skill";
import { Certification } from "./certification";
import { Coordinate, ServerDocument } from "./common";
import { Chat } from "./chat";
import { Shift } from "./shift";

export enum ProjectStatus {
  DRAFT = "draft",
  PUBLISHED = "publish",
  SCHEDULED = "scheduled",
  IN_PROGRESS = "In-Progress",
  BUDGET_APPROVAL = "budget-approval",
  COMPLETED = "completed",
  CANCELED = "canceled",
}

export interface Project {
  id: number;
  title: string;
  description: string;
  confidential_information: string;
  status: ProjectStatus;
  company_id: number;
  company: Company;
  manager_id: number;
  manager: Manager;
  customer_id: number;
  customer: Customer;
  location_id: number;
  location: Location;
  start_date: Date;
  end_date: Date;
  timezone: string;
  approximate_hours_to_complete: number;
  is_photo_required: boolean;
  tool_required: boolean;
  is_private: boolean;
  mile_radius: number;
  num_of_talent: number;
  type: "hourly" | "fixed";
  covid_vaccine_required: boolean;
  background_check_required: boolean;
  drug_test_required: boolean;
  canceled_reason?: string;
  reason_schedule?: string;
  internal_notes?: string;
  //
  skills?: Array<Skill>;
  certifications?: Array<Certification>;
  tasks?: Array<ProjectTask>;
  resources?: Array<ProjectResource>;
  reviews?: Array<ProjectReview>;
  chats?: Array<Chat>;
  documents?: Array<ProjectDocument>;
  shifts?: Array<Shift>;
  //
  created_at: Date;
  updated_at: Date;
  completed_at: Date;
  deleted_at: Date;

  hourly_rate?: number;
  total_pay?: number;
}

export interface ProjectTask {
  id: number;
  _internal_op?: "none" | "add" | "edit" | "delete";
  company_id?: number;
  project_id?: number;
  name: string;
  description?: string;
  start_date?: Date;
  end_date?: Date;
  position?: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export enum NegotiationStatus {
  NEW = "new",
  REJECTED = "rejected",
  INTERVIEW = "interview",
  INVITED = "invited",
  HIRED = "hired",
  COMPLETED = "completed",
  CANCELED = "canceled",
}

export interface ProjectResource {
  id: number;
  project_id: number;
  project?: Project;
  resource_id: number; // talent
  resource?: Talent;
  task_id: number;
  status: NegotiationStatus;
  hired_at: Date;
  rate: number;
  price: number;
  max_hour: number;
  report_progresses?: Array<ReportProgress>;
  canceled_reason?: string;
  total_working: number;
  total_earning: number;
  start_date: Date;
  timezone: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export enum ReportProgressStatus {
  SUBMITTED = "submitted",
  APPROVED = "approved",
  REJECTED = "rejected",
}
export interface ReportProgress {
  id: number;
  project_resource_id: number;
  created_by: number;
  check_in: Date;
  check_out: Date;
  status: ReportProgressStatus;
  coordinate_check_in: Coordinate;
  coordinate_check_out: Coordinate;
  documents?: Array<ServerDocument>;
  check_in_timezone: string;
  check_out_timezone: string;
  reason: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface ProjectLog {
  id: number;
  event: string;
  old_values: any;
  new_values: any;
  user_id: number;
  user?: User;
  created_at: Date;
}

export interface ProjectReview {
  id: number;
  project_id: number;
  project?: Project;
  created_by: number;
  creator: User;
  model_type: string;
  model_id: number;
  star: number;
  review: string;
  created_at: Date;
}

export interface ProjectDocument {
  id: number;
  name: string;
  file_name: string;
  original_url: string;
  size: number;
  mime_type: string;
  created_at: Date;
}
