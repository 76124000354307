import { isProductionMode } from "../utils/helper";

// export const BASE_API_URL = isProductionMode()
//   ? `https://api.manage.workz360.com/`
//   : `https://api.manage.workz360.com/`;

// export const OAUTH_CLIENT_ID = isProductionMode() ? `2` : `4`;
// export const OAUTH_CLIENT_SECRET = isProductionMode()
//   ? `NBwV57xnXUur1ucOCF5c4DT4WMVvf4uwBVKELis2`
//   : `z7HhDe1wmm4LeZgi33mq40XU0ZV1nRkzBnzMWaty`;

export const BASE_API_URL = `https://api.manage.workz360.com/`;

export const OAUTH_CLIENT_ID = `2`;
export const OAUTH_CLIENT_SECRET = `NBwV57xnXUur1ucOCF5c4DT4WMVvf4uwBVKELis2`;

export const ROLE_TALENT_ID = 5;
export const ROLE_COMPANY_ID = 6;

export const GMAP_API_KEY = `AIzaSyBFhDf7o_dEXSoMzbg06MH_V2DXPn_j374`;
