import React, { useState } from 'react';
import { Button, Card, Checkbox, TextField, Typography, FormControlLabel, Dialog, DialogContent, DialogActions } from '@mui/material';

const shifts = ['Morning', 'Afternoon', 'Evening', 'Night', 'Weekend']; // Dummy shifts
const dummyUsers = ['User1', 'User2', 'User3', 'User4', 'User5']; // Dummy users

const MessagePage: React.FC = () => {
  const [message, setMessage] = useState('');
  const [selectedShifts, setSelectedShifts] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const characterLimit = 200; // Character limit

  const handleShiftChange = (shift: string) => {
    setSelectedShifts((prev) =>
      prev.includes(shift) ? prev.filter((s) => s !== shift) : [...prev, shift]
    );
  };

  const handleUserChange = (arrayUser: string) => {
    setSelectedUsers((prev) =>
      prev.includes(arrayUser) ? prev.filter((u) => u !== arrayUser) : [...prev, arrayUser]
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };

  
  const currentTime = new Date().toLocaleString(); // Get the current date and time
  const alertMessage = `
    Message:      ${message}
    Time:         ${currentTime}
    Shifts:       ${selectedShifts.join(', ') || 'None'}
    Users:        ${selectedUsers.join(', ') || 'None'}
    `;

   const handleSubmit = () => {
    console.log('Message:', message);
    console.log('Selected Shifts:', selectedShifts);
    console.log('Selected Users:', selectedUsers);
    setOpenDialog(true); // Open the dialog when submitting
    alert(alertMessage);
};

  return (
    <div style={{ padding: '20px' }}>
      {/* Card 1: Write Your Message */}
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6">Write Your Message</Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your message here..."
          value={message}
          onChange={(e) => { if (e.target.value.length <= characterLimit) {
            setMessage(e.target.value);
          }
        }}
        inputProps={{ maxLength: characterLimit }}
      />
      <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
        {characterLimit - message.length} characters left out of {characterLimit}
      </Typography>
      </Card>

      {/* Card 2: Select Shifts */}
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6">Select Shifts</Typography>
        {shifts.map((shift) => (
          <FormControlLabel
            key={shift}
            control={
              <Checkbox
                checked={selectedShifts.includes(shift)}
                onChange={() => handleShiftChange(shift)}
              />
            }
            label={shift}
          />
        ))}
      </Card>

      {/* Card 3: Select Users */}
      <Card style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6">Select Users</Typography>
        {dummyUsers.map((arrayUser) => (
          <FormControlLabel
            key={arrayUser}
            control={
              <Checkbox
                checked={selectedUsers.includes(arrayUser)}
                onChange={() => handleUserChange(arrayUser)}
              />
            }
            label={arrayUser}
          />
        ))}
      </Card>

      {/* Send Button
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: '20px' }}
      >
        Send
      </Button> */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: '20px' }}
      >
        Send
      </Button>

      {/* Dialog for Message */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent style={{ textAlign: 'justify' }}>
          <Typography variant="h6">Message Details</Typography>
          <p><b>Message:</b> {message || 'No message provided'}</p>
          <p><b>Shifts:</b> {selectedShifts.join(', ') || 'None'}</p>
          <p><b>Users:</b> {selectedUsers.join(', ') || 'None'}</p>
          <p><b>Time:</b> {new Date().toLocaleString()}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessagePage;