import * as React from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import ErrorNotFound from "./components/ErrorNotFound";
import ProtectedPageLayout from "./components/ProtectedPageLayout";
import LoginPage from "./features/auth/LoginPage";
import SignupPage from "./features/signup/SignupPage";
// import SignupWorkerPage from "./features/signup/SignupWorkerPage";
import SignupCompanyPage from "./features/signup/SignupCompanyPage";
import DashboardPage from "./features/dashboard/DashboardPage";
import ClientsPage from "./features/client/ClientsPage";
import ClientEditPage from "./features/client/ClientEditPage";
import ClientDetailPage from "./features/client/ClientDetailPage";
import LocationsPage from "./features/location/LocationsPage";
import CalendarPage from "./features/schedule/CalendarPage";
import ProjectsPage from "./features/project/ProjectsPage";
import ProjectEditPage from "./features/project/ProjectEditPage";
import ProjectDetailPage from "./features/project/ProjectDetailPage";
import ProjectsMapPage from "./features/project/ProjectsMapPage";
import ProjectTemplatesPage from "./features/project/ProjectTemplatesPage";
import ProjectTemplateEditPage from "./features/project/ProjectTemplateEditPage";
import ProjectTemplateDetailPage from "./features/project/ProjectTemplateDetailPage";
import { useAppSelector } from "./hooks/store";
import { useGetUserQuery, useLoadUserQuery } from "./services/auth.service";
import CircularProgress from "@mui/material/CircularProgress";
import TalentsPage from "./features/talent/TalentsPage";
import TalentsMapPage from "./features/talent/TalentsMapPage";
import TalentDetailPage from "./features/talent/TalentDetailPage";
import { useCurrentUser } from "./hooks/useCurrentUser";
import ManagersPage from "./features/manager/ManagersPage";
import SignupCompleted from "./features/signup/SignupCompleted";
import { isProductionMode } from "./utils/helper";
import CornerRibbon from "./components/CornerRibbon";
import { colors, Typography } from "@mui/material";
import TalentReviewsPage from "./features/talent/TalentReviewsPage";
import manage360 from "./images/manage360.png";
import ShiftTemplatesPage from "./features/project/ShiftTemplatesPage";
import ShiftTemplateEditPage from "./features/project/ShiftTemplateEditPage";
import TalentsInvitePage from "./features/talent/TalentsInvite";
import NotificationsPage from "./features/notifications/NotificationsPage";

const Loader = () => {
  // const navigate = useNavigate();
  const token = useAppSelector((state) => state.auth.token);
  const { isLoading, refetch } = useLoadUserQuery(undefined, {
    skip: !token,
  });

  React.useEffect(() => {
    if (!!token) {
      refetch();
    }
  }, [token]);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <img src={manage360} height={80} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={18} />
        </div>
      </div>
    );
  return <Outlet />;
};

const PublicLayout = () => {
  const user = useCurrentUser();
  const isProduction = React.useMemo(() => isProductionMode(), []);
  if (!!user) return <Navigate to="/dashboard" replace />;
  return (
    <div>
      {!isProduction && (
        <CornerRibbon backgroundColor={colors.yellow[200]} fontColor="#333">
          STAGING
        </CornerRibbon>
      )}
      <Outlet />
    </div>
  );
};

const IndexPage = () => {
  return <Navigate to="/login" />;
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Loader />}>
          <Route path="/" element={<IndexPage />} />
          <Route element={<PublicLayout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            {/* <Route path="/signup/worker" element={<SignupWorkerPage />} /> */}
            <Route path="/signup/company" element={<SignupCompanyPage />} />
            <Route path="/signup/completed" element={<SignupCompleted />} />
          </Route>
          <Route element={<ProtectedPageLayout />}>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/jobs">
              <Route index element={<ProjectsPage />} />
              <Route path="create" element={<ProjectEditPage />} />
              <Route path="map" element={<ProjectsMapPage />} />
              <Route path=":projectId" element={<ProjectDetailPage />} />
              <Route path=":projectId/edit" element={<ProjectEditPage />} />
              <Route
                path=":projectId/template/create"
                element={<ProjectTemplateEditPage />}
              />
              <Route path="job-templates">
                <Route index element={<ProjectTemplatesPage />} />
                <Route path="create" element={<ProjectTemplateEditPage />} />
                <Route
                  path=":templateId"
                  element={<ProjectTemplateDetailPage />}
                />
                <Route
                  path=":templateId/edit"
                  element={<ProjectTemplateEditPage />}
                />
              </Route>
              <Route path="shift-templates">
                <Route index element={<ShiftTemplatesPage />} />
                <Route path="create" element={<ShiftTemplateEditPage />} />
                <Route
                  path=":templateId/edit"
                  element={<ShiftTemplateEditPage />}
                />
              </Route>
            </Route>
            {/* <Route path="/clients">
              <Route index element={<ClientsPage />} />
              <Route path="create" element={<ClientEditPage />} />
              <Route path=":customerId" element={<ClientDetailPage />} />
              <Route path=":customerId/edit" element={<ClientEditPage />} />
            </Route> */}
            <Route path="/calendar">
              <Route index element={<CalendarPage />} />
            </Route>
            <Route path="/locations">
              <Route index element={<LocationsPage />} />
            </Route>
            <Route path="/employees">
              <Route index element={<TalentsPage />} />
              {/* <Route path="create" element={<TalentEditPage />} /> */}
              <Route path="map" element={<TalentsMapPage />} />
              <Route path="invite" element={<TalentsInvitePage />} />
              <Route path=":talentId" element={<TalentDetailPage />} />
              {/* <Route path=":talentId/edit" element={<TalentEditPage />} /> */}
              <Route path=":talentId/reviews" element={<TalentReviewsPage />} />
            </Route>
            <Route path="/managers">
              <Route index element={<ManagersPage />} />
            </Route>
            <Route path="/notifications">
              <Route index element={<NotificationsPage />} />
            </Route>
            <Route path="/reports">
              <Route
                index
                element={
                  <Typography>
                    ← Please select report page from the sidebar menu
                  </Typography>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<ErrorNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoutes;
